.nextButton {
  margin-top: rem(20);
  min-width: rem(200);
  height: rem(50);

  @mixin transition opacity;
}

.disabled {
  opacity: 0;
  pointer-events: none;
}

.poll {
  @mixin transition opacity;
}

.submitting {
  pointer-events: none;
  opacity: 0.7;
}

.choicesDoubleCols {
  @media bp(sm) {
    column-gap: rem(15);
    grid-template-columns: repeq(2);
  }
}
